import { useState, useEffect } from 'react';
import {
  HomeOutlined,
  UserOutlined,
  CreditCardOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import api from '../api';
import isCorrectNumberPhone from '../utils/isCorrectNumberPhone'
function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;
  console.log('platform: ', platform);

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
} 
export default function Navigator() {
  const history = useHistory();
  const [current, setCurrent] = useState('home');
  const [systemConfig, setSystemConfig] = useState({})
  async function getSystemConfig() {
    const { data } = await api.get("/systemConfiguration/getSystemConfig");
    setSystemConfig(data.data)
  }
  useEffect(() => {
    getSystemConfig()
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 60,
        padding: '0px 30px',
        borderTop: '1px solid #eee',
        paddingBottom: 10,
        background: '#fff',
      }}
    >
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 60,
          borderRadius: 25,
          minWidth: 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={() => {
          setCurrent('wallet');
          history.push('/wallet');
        }}
      >
        <CreditCardOutlined
          style={{
            fontSize: 20,
            color: current == 'wallet' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'wallet' ? '#002dbf' : '#666',
          }}
        >
          Ví tiền
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/');
          setCurrent('home');
        }}
      >
        <HomeOutlined
          style={{
            fontSize: 20,
            color: current == 'home' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'home' ? '#002dbf' : '#666',
          }}
        >
          Trang chủ
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={async () => {
          const os = getOS();
          window.open(systemConfig?.telegramUrl || `https://m.me/sacombank.msign.ipay`, "_blank")
        }}
      >
        <PhoneOutlined
          style={{
            fontSize: 20,
            color: current == 'cskh' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'cskh' ? '#002dbf' : '#666',
          }}
        >
          CSKH
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/me');
          setCurrent('me');
        }}
      >
        <UserOutlined
          style={{
            fontSize: 20,
            color: current == 'me' ? '#002dbf' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'me' ? '#002dbf' : '#666',
          }}
        >
          Hồ sơ
        </Typography.Text>
      </motion.div>
    </div>
  );
}
